import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import TextLogo from 'assets/svg/brand/text-logo-white.svg'
import { Body } from 'components/Text'
import { FlexDivColCentered } from 'components/layout/flex'
import { EXTERNAL_LINKS } from 'constants/links'
import type { HTMLAttributes } from 'react'
import { ExternalLink } from 'styles/common'

type PoweredBySynthetixProps = {
	props?: HTMLAttributes<HTMLDivElement>
	logoHeight?: string
	logoWidth?: string
}

const PoweredBySynthetix = ({
	logoHeight = '10.5px',
	logoWidth,
	...props
}: PoweredBySynthetixProps) => {
	const { t } = useTranslation()
	return (
		<Container {...props}>
			<Text>{t('common.powered-by')}</Text>
			<ExternalLink href={EXTERNAL_LINKS.Synthetix.Home}>
				<TextLogo height={logoHeight} width={logoWidth} />
			</ExternalLink>
		</Container>
	)
}

const Container = styled(FlexDivColCentered)`
	row-gap: 5px;
`

const Text = styled(Body)`
	font-size: 10px;
	color: ${(props) => props.theme.colors.common.primaryWhite};
	opacity: 0.5;
	letter-spacing: 0.04em;
	font-variant: small-caps;
	text-transform: uppercase;
	line-height: 100%;
	text-align: center;
`

export default PoweredBySynthetix

import Image from 'next/image'
import Link from 'next/link'
import { type FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css, keyframes } from 'styled-components'

import ArrowUpRightIcon from 'assets/svg/app/arrow-up-right-tg.svg'
import CloseIcon from 'assets/svg/app/close.svg'
import LinkIcon from 'assets/svg/app/link.svg'
import Button from 'components/Button'
import { Body } from 'components/Text'
import { EXTERNAL_LINKS } from 'constants/links'
import useClickOutside from 'hooks/useClickOutside'
import { fetchBlogPosts } from 'state/home/actions'
import { selectBlogPosts } from 'state/home/selectors'
import { useAppSelector, useFetchAction } from 'state/hooks'
import media from 'styles/media'

const Blog = () => {
	const { t } = useTranslation()
	const posts = useAppSelector(selectBlogPosts)
	const [open, setOpen] = useState(true)
	const { ref } = useClickOutside(() => setOpen(false))

	const [activeIndex, setActiveIndex] = useState(0)

	const postCount = useMemo(() => posts?.length ?? 0, [posts])

	useEffect(() => {
		if (!open) return
		const interval = setInterval(() => {
			setActiveIndex((prevIndex) => (prevIndex + 1) % postCount)
		}, 3000)
		return () => clearInterval(interval)
	}, [postCount, open])

	useFetchAction(fetchBlogPosts, { name: 'fetchBlogPosts', dependencies: [] })

	const handleToggleBlog = () => setOpen((o) => !o)

	const memoizedPosts = useMemo(
		() =>
			posts?.map((post) => (
				<Slide key={post.id} href={post.url} target="_blank">
					<Wrapper>
						<ImageContainer>
							{!!post.feature_image && (
								<ImageWithFallback
									src={post.feature_image}
									fallback="/images/blog-post-fallback.jpeg"
									alt="post-image"
									width={330}
									height={190}
								/>
							)}
						</ImageContainer>
					</Wrapper>
					<PostTitle>{post.title}</PostTitle>
					<PostContent>{post.excerpt}</PostContent>
				</Slide>
			)),
		[posts]
	)

	return (
		<Container open={open} ref={ref}>
			<Header onClick={handleToggleBlog}>
				<StyledBody mono>Blog</StyledBody>
				<ActionButton>
					{open ? (
						<CloseIcon width={10} height={10} />
					) : (
						<ArrowUpRightIcon width={20} height={20} />
					)}
				</ActionButton>
			</Header>
			<Carousel visible={open}>
				<SlideContainer translateX={activeIndex * 100} open={open}>
					{memoizedPosts}
				</SlideContainer>
			</Carousel>
			<Footer visible={open}>
				<IndicatorContainer>
					{posts?.map((_, index) => (
						<Indicator
							key={_.title}
							isActive={index === activeIndex}
							onClick={() => setActiveIndex(index)}
						/>
					))}
				</IndicatorContainer>
				<Link href={`${EXTERNAL_LINKS.Social.Blog}`} target="_blank">
					<Button size="xsmall">
						{t('homepage.blog.read-more')}
						<ExternalLink width={13} height={13} />
					</Button>
				</Link>
			</Footer>
		</Container>
	)
}

type ImageWithFallbackProps = {
	fallback: string
	alt: string
	src: string
	width: number
	height: number
}

const ImageWithFallback: FC<ImageWithFallbackProps> = ({ fallback, alt, src, ...props }) => {
	const [error, setError] = useState(false)

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		setError(false)
	}, [src])

	return <Image alt={alt} onError={() => setError(true)} src={error ? fallback : src} {...props} />
}

export default Blog

const pulse = keyframes`
  0%, 100% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
`

const IndicatorContainer = styled.div`
	display: flex;
`

const Wrapper = styled.div`
  height: 190px;
  display: flex;
`

const Indicator = styled.div<{ isActive: boolean }>`
	width: 20px;
	height: 5px;
	background: ${(props) => props.theme.colors.common.secondaryGray};
	margin: 30px 5px;
	cursor: pointer;
	border-radius: 5px;
	${({ isActive }) =>
		isActive &&
		css`
			background: ${(props) => props.theme.colors.selectedTheme.white};
			animation: ${pulse} 1s ease-in-out infinite;
		`}
`

const SlideContainer = styled.div<{ translateX: number; open: boolean }>`
	display: flex;
	flex-direction: row;
	transform: ${({ translateX }) => `translateX(-${translateX}%)`};
	transition: transform 0.5s ease-out;
	width: 330px;
`

const Slide = styled.a`
	display: flex;
	cursor: pointer;
	align-items: center;
	flex-direction: column;
	flex: 0 0 100%;
	transition: all 0.5s ease;
`

const Carousel = styled.div<{ visible: boolean }>`
	display: ${(props) => (props.visible ? 'flex' : 'none')};
	flex: 1;
	overflow: hidden;
	position: relative;
	justify-content: flex-start;
	padding-top: 10px;
`

const Container = styled.div<{ open: boolean }>`
	display: flex;
	flex-direction: column;
	padding: ${({ open }) => (open ? '10px 20px 0px 20px' : '0 20px')};
	justify-content: ${({ open }) => (open ? 'flex-start' : 'center')};
	position: absolute;
	height: ${({ open }) => (open ? '440px' : '60px')};
	border-radius: 12px;
	border: ${(props) => props.theme.colors.selectedTheme.landing.border};
	background-color: rgba(13, 13, 13, 0.2);
	backdrop-filter: blur(20px);
	bottom: 20px;
	right: 40px;
	z-index: 20;
	width: 370px;
	transition: height 0.2s ease;

	${media.lessThan('lg')`
    display: none;
  `}
`

const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
`

const Footer = styled.div<{ visible: boolean }>`
	display: ${(props) => (props.visible ? 'flex' : 'none')};
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 70px;
	border-top: ${(props) => props.theme.colors.selectedTheme.landing.border};
	width: 100%;
`

const ExternalLink = styled(LinkIcon)`
	margin-left: 5px;
	path {
		fill: ${(props) => props.theme.colors.selectedTheme.gray};
	}
`

const StyledBody = styled(Body)`
	font-size: 16px;
	weight: 400;
	line-height: 24px;
	letter: -4%;
`

const ActionButton = styled(Button)`
	display: grid;
	place-items: center;
	height: 32px;
	width: 32px;
	padding: 0px;

	& svg {
		path {
			stroke: ${(props) => props.theme.colors.selectedTheme.white};
		}
	}
`

const ImageContainer = styled.div`
	height: 100%;
	width: 100%;
	& > img {
		object-fit: cover;
	}
`

const PostTitle = styled(Body)`
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
	letter-spacing: -0.04em;
	padding-top: 10px;
`

const PostContent = styled(Body)`
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: -0.04em;
	text-align: left;
	color: ${(props) => props.theme.colors.common.tertiaryGray};
	text-align: left;
	padding-top: 10px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	width: 100%;
`

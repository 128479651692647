import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Body } from 'components/Text'
import { FlexDivCol, FlexDivRow } from 'components/layout/flex'
import { DEFAULT_PERPS_PROVIDER } from 'constants/futures'
import ROUTES from 'constants/routes'
import RefButton from 'sections/shared/components/RefButton'
import media from 'styles/media'

const Hero = () => {
	const { t } = useTranslation()
	return (
		<Container>
			<Title>
				<TitleText mono>
					<Yellow>trade</Yellow> perpetual futures
				</TitleText>
			</Title>
			<SubTitleText>
				Gain exposure to a variety of assets with up to <Yellow>50x leverage</Yellow>
				&nbsp;and <Yellow>deep liquidity</Yellow>.
			</SubTitleText>
			<Link
				className="trade"
				href={ROUTES.Markets.Home(DEFAULT_PERPS_PROVIDER)}
				passHref
				legacyBehavior
			>
				<StyledRefButton variant="solid-yellow" noOutline size="large">
					{t('homepage.nav.trade-now')}
				</StyledRefButton>
			</Link>
		</Container>
	)
}

const Container = styled(FlexDivCol)`
	position: relative;
	z-index: 20;
	& > a {
		margin-top: 25px;
	}
`

const StyledRefButton = styled(RefButton)`
	margin-top: 25px;
	font-size: 18px;
	text-transform: uppercase;
`
const Title = styled(FlexDivRow)`
	justify-content: flex-start;
	margin-bottom: 5px;
`
const TitleText = styled(Body)<{ yellow?: boolean }>`
	text-transform: uppercase;
	word-spacing: 10px;
	font-size: 62px;
	line-height: 68.2px;
	font-weight: 700;
	${media.lessThan('lg')`
		font-size: 32px;
		font-weight: 700;
		line-height: 40px;
		letter-spacing: 0em;
		word-spacing: 0em;
  `}
`

const SubTitleText = styled(Body)`
	font-size: 20px;
	line-height: 28px;
	font-weight: 400;
	${media.lessThan('lg')`
		font-size: 14px;
		line-height: 20px;
  `}
`

const Yellow = styled.span`
	color: ${(props) => props.theme.colors.common.primaryYellow};
	font-weight: 700;
`
export default Hero
